import * as HttpService from "../../service/HttpService";

/**
 * 树列表
 * @param params 参数列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12274
 */
export const treeByStation = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/adCode/treeByStation", params);
};
/**
 * 防灾应急电站列表
 * @param params 参数列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12278
 */
export const queryStationList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operPreventDisaster/queryStationList", params);
};

/**
 * 有序充电电站列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12306
 */
export const queryOrderlyChargeStationList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operOrderlyCharge/queryStationList", params);
};

/**
 * 电柜设备工况分页查询
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/10282
 * @param {*} params 
 * @returns 
 */
export const selectOpsDevCabinetPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/opsDevCabinet/selectOpsDevCabinetPage", params);
};
/**
 * 发送验证码
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12310
 * @param {*} params 
 * @returns 
 */
export const sendSms = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/cfc/sendSms", params);
};
/**
 * 执行防灾应急
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12318
 * @param {*} params 
 * @returns 
 */
export const executePreventDisaster = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/operPreventDisaster/execute", params);
};
/**
 * 结束防灾应急
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12314
 * @param {*} params 
 * @returns 
 */
export const terminatePreventDisaster = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/operPreventDisaster/terminate", params);
};


