import * as HttpService from "../../service/HttpService";

/**
 * 查询项目进度概况
 * @param params 参数列表
 */
export const getOverviewProjectSchedule = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/largeScreen/getOverviewProjectSchedule", params);
};
/**
 * 获取标签词云
 */
export const getTagWordCloud = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-crm/largeScreen/getTagWordCloud");
};
/*
 *获取客户数据概览
 */
export const getCustomDataOverview = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-crm/largeScreen/getCustomDataOverview");
};
/*
 *客户经理月度走访指标完成情况
 */
export const getAccountManagers = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-crm/largeScreen/getAccountManagers");
};

/*
 *客户用电量Top
 */
export const getTopElectricityConsumers = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-crm/largeScreen/getTopElectricityConsumers");
};
/*
 *获取客户所属地区排行榜
 */
export const getCustomerAreaRanking = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/largeScreen/getCustomerAreaRanking", params);
};
/*
 *大屏所有客户经纬度
 */
export const gettrack = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/user/track", data);
};
/*
 *大屏所有客户经理经纬度
 */
export const queryTrack = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/accountManager/queryTrack", data);
};
/*
 *根据用户id查询用户画像
 */
export const getUserPortrait = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/largeScreen/getUserPortrait", data);
};
/*
 *获取天气
 */
export const getWeatherInfo = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/largeScreen/getWeatherInfo", data);
};
/*
 *获取标签
 */
export const queryTag = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/tag/query", data);
};
/*
 *标签查询
 */
 export const tagQueryUser = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-crm/dataOverview/tagQueryUser", data);
};
/*
 *客户概览弹窗 -- 项目规模
 */
 export const getProjectscale = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/largeScreen/getProjectscale", data);
};