import "whatwg-fetch";
import HttpConfig from "../config/HttpConfig";
import * as HttpService from "./HttpService";
import { encryptByAES, decryptedByAES } from "../utils/Crypto";

const DOMAIN = HttpConfig.HTTP_APPLICATION_SERVER;

const CLIENT_BASIC_AUTH = HttpConfig.CLIENT_BASIC_AUTH; // 请求认证服务器basic加密

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return HttpService.get(DOMAIN + "/gaea-system/users/getUserDetail");
};

// /**
//  * 插入登录日志
//  */
// export const addLoginLog = (params) => {
//   return HttpService.post(DOMAIN + "/LoginLog/addLoginMessage", params);
// };

/**
 * 根据项目id获取菜单列表
 * @param parentId 项目id
 */
export const getMenuListByProjectId = (no, code) => {
  return HttpService.get(DOMAIN + "/gaea-system/menus/menu-items-tree?appNo=" + no + "&menuTreeCode=" + code);
};
/**
 * 修改密码
 * @param oldPassword 原密码
 * @param newPassword 新密码
 */
export const changePassword = (data) => {
  return HttpService.post(DOMAIN + "/gaea-system/users/modifyUserPas", data);
};
/**
 * 设置边端客服电话
 */
export const setCustomerServicePhoneNumber = (data) => {
  return HttpService.getNormal(DOMAIN + "/gaea-cabinet/edgeSettings/setCustomerServicePhoneNumber", data);
};
/**
 * 设置边端密码
 */
export const sendPwd = (data) => {
  return HttpService.put_param(DOMAIN + "/gaea-cabinet/edgeSettings/sendPwd", data);
};
/**
获取边端客服电话
 */
export const getCustomerServicePhoneNumber = () => {
  return HttpService.get(DOMAIN + "/gaea-cabinet/edgeSettings/getCustomerServicePhoneNumber");
};
/**
 * 编辑个人信息
 * @param profile
 */
export const editProfile = (profile) => {
  const fd = new FormData();
  fd.append("userFullname", profile.userFullname);
  fd.append("userMobile", profile.userMobile);
  return HttpService.postForm(DOMAIN + "/modifyProfile", fd);
};

/**
 * 登录
 * @param username 用户名
 * @param password 密码
 */
export const login = (username, password) => {
  // const pwd = username + "@@" + encryptByAES(password);
  const pwd = username + "@@" + password;
  let formData = new FormData();
  formData.append("grant_type", "password");
  formData.append("username", username);
  formData.append("password", pwd);
  formData.append("scope", "all");
  return request(DOMAIN + "/gaea-auth/oauth/token", {
    method: "POST",
    body: formData,
    headers: { Authorization: CLIENT_BASIC_AUTH },
  });
};

/**
 * 封装fetch请求，支持异常捕获
 * @param url
 * @param options
 * @returns {Promise<Response>}
 */
export function request(url, options) {
  let opt = options || {};
  return fetch(url, { ...opt })
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => data);
}
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}
function parseJSON(response) {
  return response.json();
}
