import * as HttpService from "../../service/HttpService";

/**
 * 查询客户经理档案
 * @param params 参数列表
 */
export const getInterviewList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/userInterview/selectInterviewList", params);
};
/**
 * 计费套餐分页列表
 * @param params 参数列表
 */
export const getBillingTmplList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/billingTmpl", params);
};

/**
 * 新增套餐
 * @param params 参数列表
 */
export const addBillingTmpl = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/billingTmpl", params);
};

/**
 * 新增走访派单
 * @param params 参数列表
 */
export const visitRegister = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-crm/userInterview/visitRegister", params);
};
/**
 * 编辑走访派单
 * @param params 参数列表
 */
export const editUserInterview = (params) => {
  return HttpService.put_param(HttpService.DOMAIN + "/gaea-crm/userInterview/editUserInterview", params);
};
/**
 * 查询走访详情
 * @param params 参数列表
 */
export const getVisitDetailById = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/userInterview/selectInterviewById", params);
};
/**
 * 删除走访计划
 * @param params 参数列表
 */
export const deleteUserInterview = (params) => {
  return HttpService.del(HttpService.DOMAIN + "/gaea-crm/userInterview/deleteUserInterview?ids=" + params);
};
/**
 * 根据走访主键id 查询 走访详情
 * @param params 参数列表
 */
export const selectInterviewById = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/userInterview/selectInterviewById", params);
};

/**
 * 导出走访列表
 * @param params 参数列表
 */
export const exportInterviewList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/userInterview/exportInterviewList", params);
};

/*
 *获取未签约客户树 区域+客户
 */
 export const getDisPatchedClient = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/user/queryUnDispatched",params);
};

/*
 *获取走访统计
 */
 export const getVisitDataStatistics = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/userInterview/getVisitDataStatistics",params);
};

