import RequestService from "../../service";
import { message } from "antd";
import * as common from "../BusinessPackage/common";

export const SYS_MGT_BUSINESS_PACKAGE_LIST_INIT = "SYS_MGT_BUSINESS_PACKAGE_LIST_INIT"; // 初始化业务包列表
export const SYS_MGT_BUSINESS_PACKAGE_LIST_EXPAND = "SYS_MGT_BUSINESS_PACKAGE_LIST_EXPAND"; // 业务包列表
export const SYS_MGT_BUSINESS_PACKAGE_TABLE_LOADING = "SYS_MGT_BUSINESS_PACKAGE_TABLE_LOADING"; // 表加载中
export const SYS_MGT_BUSINESS_PACKAGE_EXPANDED_ROW_KEYS = "SYS_MGT_BUSINESS_PACKAGE_EXPANDED_ROW_KEYS"; // 展开的行key
export const SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO = "SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO"; // 业务包模态框信息
export const SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO_IS_LOADING =
  "SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO_IS_LOADING"; // 业务包模态框 确认加载
export const SYS_MGT_BUSINESS_PACKAGE_ADD_PACKAGE = "SYS_MGT_BUSINESS_PACKAGE_ADD_PACKAGE"; // 业务包新增
export const SYS_MGT_BUSINESS_PACKAGE_DELETE_PACKAGE = "SYS_MGT_BUSINESS_PACKAGE_DELETE_PACKAGE"; // 业务包新增
export const SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO = "SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO"; //功能组件模态框
export const SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO_IS_LOADING =
  "SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO_IS_LOADING"; // 功能组件模态框 确认加载
export const SYS_MGT_BUSINESS_PACKAGE_EDIT_PACKAGE = "SYS_MGT_BUSINESS_PACKAGE_EDIT_PACKAGE"; // 业务包编辑
export const SYS_MGT_BUSINESS_PACKAGE_MODULE_TREE_INIT = "SYS_MGT_BUSINESS_PACKAGE_MODULE_TREE_INIT"; //初始化组件树
/**
 * 获取包列表  appId: 应用id className: 类型名称
 */
export const getPackageList = (params) => {
  const { orgType, ...paramsRest } = params;
  return (dispatch) => {
    dispatch({
      type: SYS_MGT_BUSINESS_PACKAGE_TABLE_LOADING,
      isLoading: true,
    });
    RequestService.SystemService.getBusinessPackageList(paramsRest)
      .then((json) => {
        if (json.code == 200) {
          // params.parentId === "" && orgType === 0 总账号登陆 
          // params.parentId === "0" && orgType !== 0 业务管理员账号登陆 
          if ((params.parentId === "" && orgType === 0) || (params.parentId === "0" && orgType !== 0)) {
            dispatch({
              type: SYS_MGT_BUSINESS_PACKAGE_LIST_INIT,
              data: json.data,
              params: params,
            });
          } else {
            // 拼接新数据
            dispatch({
              type: SYS_MGT_BUSINESS_PACKAGE_LIST_EXPAND,
              dataList: json.data,
              packageId: params.packageId,
            });
          }
        } else {
          message.error(json.msg);
        }
      })
      .catch((e) => {
        console.error("获取业务包列表失败:" + e);
        message.error("业务包获取失败");
      })
      .then(() =>
        dispatch({
          type: SYS_MGT_BUSINESS_PACKAGE_TABLE_LOADING,
          isLoading: false,
        })
      );
  };
};

/**
 * 设置展开的行号
 */
export const setExpandedRowKeys = (expandedRowKeys) => {
  return {
    type: SYS_MGT_BUSINESS_PACKAGE_EXPANDED_ROW_KEYS,
    expandedRowKeys: expandedRowKeys,
  };
};

/**
 * 设置业务包模态框信息
 */
export const setPackageModalInfo = (packageModal) => {
  return {
    type: SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO,
    packageModalInfo: packageModal,
  };
};
/**
 * 设置功能组件模态框信息
 */
export const setModuleModalInfo = (moduleModal) => {
  return {
    type: SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO,
    moduleModalInfo: moduleModal,
  };
};

/**
 * 新增业务包
 */
export const addPackage = (packageInfo) => {
  let data = {
    appId: packageInfo.appId,
    packageType: packageInfo.type,
    packageName: packageInfo.name,
    packageDesc: packageInfo.desc,
    packageLevel: packageInfo.packageLevel,
    parentId: packageInfo.parentId,
    sortSn: packageInfo.sortSn,
  };
  return (dispatch) => {
    dispatch({
      type: SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO_IS_LOADING,
      isLoading: true,
    });
    RequestService.SystemService.addPackage(data)
      .then((json) => {
        if (json.code == 200) {
          dispatch({
            type: SYS_MGT_BUSINESS_PACKAGE_ADD_PACKAGE,
            pack: json.data,
            parentId: packageInfo.parentId,
          });
          dispatch(
            setPackageModalInfo({
              visible: false,
            })
          );
          message.success("新增成功");
          dispatch(
            getPackageList({
              parentId: packageInfo.parentId,
              packageId: packageInfo.parentId,
              className: common.PackageClassName.PACKAGE,
              appNo: packageInfo.appNo,
              orgType:packageInfo.orgType
            })
          );
        } else {
          message.error(json.msg);
        }
      })
      .catch((e) => {
        console.error("新增业务包失败:" + e);
        message.error("新增业务包失败");
      })
      .then(() =>
        dispatch({
          type: SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO_IS_LOADING,
          isLoading: false,
        })
      );
  };
};

/**
 * 删除业务包
 */
export const deletePackage = (packageId) => {
  return (dispatch) => {
    message.loading({ content: "删除中...", key: packageId, duration: 0 });
    RequestService.SystemService.delPackage(packageId)
      .then((json) => {
        if (json.code == 200) {
          message.success({ content: "删除成功", key: packageId });
          dispatch({
            type: SYS_MGT_BUSINESS_PACKAGE_DELETE_PACKAGE,
            packageId: packageId,
          });
        } else {
          message.error({ content: json.msg, key: packageId });
        }
      })
      .catch((e) => {
        console.error("删除业务包失败:" + e);
        message.error({ content: "删除失败", key: packageId });
      });
  };
};

/**
 * 编辑业务包
 */
export const editPackage = (packageInfo) => {
  let data = {
    id: packageInfo.id,
    appId: packageInfo.appId,
    packageType: packageInfo.type,
    packageName: packageInfo.name,
    packageDesc: packageInfo.desc,
    sortSn: packageInfo.sortSn,
  };
  return (dispatch) => {
    dispatch({
      type: SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO_IS_LOADING,
      isLoading: true,
    });
    RequestService.SystemService.editPackage(data)
      .then((json) => {
        if (json.code == 200) {
          dispatch({
            type: SYS_MGT_BUSINESS_PACKAGE_EDIT_PACKAGE,
            pack: packageInfo,
          });
          dispatch(
            setPackageModalInfo({
              visible: false,
            })
          );
          message.success("修改成功");
        } else {
          message.error(json.msg);
        }
      })
      .catch((e) => {
        console.error("修改业务包失败:" + e);
        message.error("修改业务包失败");
      })
      .then(() =>
        dispatch({
          type: SYS_MGT_BUSINESS_PACKAGE_PACKAGE_MODAL_INFO_IS_LOADING,
          isLoading: false,
        })
      );
  };
};

/**
 * 新增功能组件
 */
export const addModule = (moduleInfo) => {
  let moduleData = {
    appId: moduleInfo.appId,
    packageId: moduleInfo.packageId,
    moduleType: moduleInfo.type,
    moduleName: moduleInfo.name,
    moduleDesc: moduleInfo.desc,
    moduleActionType: moduleInfo.actionType,
    moduleLevel: moduleInfo.moduleLevel,
    parentId: moduleInfo.packageId,
    sortSn: moduleInfo.sortSn,
  };
  if (moduleInfo.type === 11) {
    moduleData = {
      ...moduleData,
      moduleCode: moduleInfo.actionPath,
    };
  } else {
    moduleData = {
      ...moduleData,
      moduleActionPath: moduleInfo.actionPath,
    };
  }
  return (dispatch) => {
    dispatch({
      type: SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO_IS_LOADING,
      isLoading: true,
    });
    RequestService.SystemService.addModule(moduleData)
      .then((json) => {
        if (json.code == 200) {
          dispatch({
            type: SYS_MGT_BUSINESS_PACKAGE_ADD_PACKAGE,
            pack: json.data,
            parentId: moduleInfo.packageId,
          });
          dispatch(
            setModuleModalInfo({
              visible: false,
            })
          );
          message.success("新增成功");
          dispatch(
            getPackageList({
              parentId: moduleInfo.packageId,
              packageId: moduleInfo.packageId,
              className: common.PackageClassName.MODULE,
              appNo: moduleInfo.appNo,
              orgType:moduleInfo.orgType
            })
          );
        } else {
          message.error(json.msg);
        }
      })
      .catch((e) => {
        console.error("新增功能组件失败:" + e);
        message.error("新增功能组件失败");
      })
      .then(() =>
        dispatch({
          type: SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO_IS_LOADING,
          isLoading: false,
        })
      );
  };
};

/**
 * 删除功能组件
 */
export const deleteModule = (moudleId) => {
  return (dispatch) => {
    message.loading({ content: "删除中...", key: moudleId, duration: 0 });
    RequestService.SystemService.delModule(moudleId)
      .then((json) => {
        if (json.code == 200) {
          message.success({ content: "删除成功", key: moudleId });
          dispatch({
            type: SYS_MGT_BUSINESS_PACKAGE_DELETE_PACKAGE,
            packageId: moudleId,
          });
        } else {
          message.error({ content: json.msg, key: moudleId });
        }
      })
      .catch((e) => {
        console.error("删除业务包失败:" + e);
        message.error({ content: "删除失败", key: moudleId });
      });
  };
};

/**
 *  编辑功能组件
 * @param moduleInfo 功能组件信息
 */
export const editModule = (moduleInfo) => {
  let data = {
    id: moduleInfo.id,
    moduleType: moduleInfo.type,
    moduleName: moduleInfo.name,
    moduleDesc: moduleInfo.desc,
    moduleActionType: moduleInfo.actionType,
    sortSn: moduleInfo.sortSn,
  };
  if (moduleInfo.type === 11) {
    data = {
      ...data,
      moduleCode: moduleInfo.actionPath,
    };
  } else {
    data = {
      ...data,
      moduleActionPath: moduleInfo.actionPath,
    };
  }
  return (dispatch) => {
    dispatch({
      type: SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO_IS_LOADING,
      isLoading: true,
    });
    RequestService.SystemService.editModule(data)
      .then((json) => {
        if (json.code == 200) {
          dispatch({
            type: SYS_MGT_BUSINESS_PACKAGE_EDIT_PACKAGE,
            pack: moduleInfo,
          });
          dispatch(
            setModuleModalInfo({
              visible: false,
            })
          );
          message.success("修改成功");
        } else {
          message.error(json.msg);
        }
      })
      .catch((e) => {
        console.error("修改功能组件失败:" + e);
        message.error("修改功能组件失败");
      })
      .then(() =>
        dispatch({
          type: SYS_MGT_BUSINESS_PACKAGE_MODULE_MODAL_INFO_IS_LOADING,
          isLoading: false,
        })
      );
  };
};

/**
 *  获取组件树
 * @param params
 */
export const getModuleTree = (params) => {
  return (dispatch) =>
    new Promise((resolve) => {
      RequestService.SystemService.getBusinessPackageList(params)
        .then((json) => {
          if (params.isLoad) {
            resolve();
          }
          if (json.code == 200) {
            dispatch({
              type: SYS_MGT_BUSINESS_PACKAGE_MODULE_TREE_INIT,
              moduleTree: json.data,
              isLoad: params.isLoad,
            });
          } else {
            message.error(json.msg);
          }
        })
        .catch((e) => {
          console.error("组件获取失败:" + e);
          message.error("组件获取失败");
        });
    });
};
