import * as HttpService from "../../service/HttpService";

/**
 * 获取客户列表
 * @param params 参数列表
 */
export const getClientList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/user/query",params);
};
/**
 * 根据商机编号查询用户信息
 * @param params 参数列表
 */
 export const queryUsersByOpportunity = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/user/queryUsersByOpportunity",params);
};
/**
 * 根据id获取客户列表
 * @param params 参数列表
 */
export const getClientDetailById = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/user/get",params);
};

/**
 * 获取客户详情头部数据
 * @param params 参数列表
 */
export const getClientHeaderInfo = (id) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/user/getCustomerInfoById/"+id);
};
/**
/**
 * 获取行业分类树
 * @param params 参数列表
 */
export const getIndustryClassTree = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-system/codeSort/tree",params);
};
/**
 * 获取已合作内容标签列表
 * @param params 参数列表  tagLevel 传 1 
 */
export const getTags = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/tag/query",params);
};

/* 
 *新增客户
 */
export const addClientApi = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-crm/user", params);
};
/*
 *修改客户
 */
export const updateClientApi = (params) => {
  return HttpService.put_param(HttpService.DOMAIN + "/gaea-crm/user", params);
};
/*
 *删除客户
 */
export const deleteClientApi = (params) => {
  return HttpService.del(HttpService.DOMAIN + "/gaea-crm/user/"+params.join(','));
};
/*
 *根据客户id获取商机列表
 */
export const getOpportunityList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/user/opportunity",params);
};
/*
 *签约商机 传商机的id
 */
export const updateOpportunityStatus = (id) => {
  return HttpService.put_param(HttpService.DOMAIN + "/gaea-crm/crmOpportunity/updateOpportunityStatus?id="+id);
};


