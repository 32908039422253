import * as HttpService from "../../service/HttpService";

// 数据概览
/*
 *站点概况及客户规模数据
 */
export const getElecStaUserInfo = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecStaUserInfo");
};
export const getElecInComExchange = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecInComExchange");
};
export const getElecDataOverCellOrderList = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecDataOverCellOrderList");
};
export const getPowerChangCount = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getPowerChangCount");
};
/*
 *虚拟电厂
 */
export const getElecVirtualPowerPlantData = (params) => {
  return HttpService.getNormal(
    HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecVirtualPowerPlantData",
    params
  );
};
/*
 *虚拟电厂
 */
export const getElecOverConduitData = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecOverConduitData");
};
/*
 *资产现状
 */
export const getElecAssetStatusData = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecAssetStatusData", params);
};
/*
 *地图数据
 */
export const getElecDataOverMapData = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecDataOverview/getElecDataOverMapData",params);
};
/*
 * 客户经理排名
 */
export const getAccountManageRank = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/dataOverview/getAccountManageRank", data);
};
/*
 * 数据统计
 */
export const calculateStatistics = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/dataOverview/calculateStatistics", data);
};
/*
 * 各区域项目数排名
 */
export const getRegionalProjectRanking = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/dataOverview/getRegionalProjectRanking", data);
};
/*
 * 标签查询
 */
export const tagQueryUser = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-crm/dataOverview/tagQueryUser", data);
};
/*
 * 标签查询
 */
export const getCustomIndustryRank = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/dataOverview/getCustomIndustryRank", data);
};
