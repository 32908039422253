import * as HttpService from "../../service/HttpService";
/**
 * 获取业务包列表
 * @param params 参数列表
 */
export const getBusinessPackageList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-system/packages/package-module-mix-lazy-tree",params);
};
/*
 *新增业务包
 */
export const addPackage = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/packages/addPackage", params);
};

/*
 *编辑业务包
 */
export const editPackage = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/packages/editPackage", params);
};

/*
 *删除业务包
 */
export const delPackage = (params) => {
  return HttpService.del(HttpService.DOMAIN + "/gaea-system/packages/delPackage/" + params);
};
/*
 *新增功能组件
 */
export const addModule = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/modules/addModule", params);
};
/*
 *删除功能组件
 */
export const delModule = (params) => {
  return HttpService.del(HttpService.DOMAIN + "/gaea-system/modules/delModule/" + params);
};
/*
 *编辑功能组件
 */
export const editModule = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/modules/editModule", params);
};

/*----------------------------------------------菜单管理页面-------------------------------------------------  */
/*
 *查询菜单
 */
export const getMenuList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-system/menus/menu-items-lazeTree", params);
};

/*
 *新增菜单
 */
export const addMenu = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/menus/addMenu", params);
};
/*
 *编辑菜单
 */
export const editMenu = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/menus/editMenu", params);
};
/*
 *删除菜单
 */
export const delMenu = (id) => {
  return HttpService.del(HttpService.DOMAIN + "/gaea-system/menus/delMenu/" + id);
};

/*
获取角色列表
*/
export const getRolesList = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-system/roles/getRolesList", data);
};
/*
获取角色信息
*/
export const getRoleDetail = (id) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/roles/getRoleDetail/" + id);
};

/*
新建角色
*/
export const addRole = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/roles/addRole", data);
};

/*
编辑
*/
export const editRole = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/roles/editRole", data);
};

/*
删除角色
*/
export const delRole = (id) => {
  return HttpService.del(HttpService.DOMAIN + "/gaea-system/roles/delRole/" + id);
};

/*
 *组织权限
 */

export const getDeptList = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-system/orgAnddepts/org-dept-mix-lazy-tree", data);
};

/*
 *新建部门
 */

export const addDept = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/depts/addDept", data);
};

/*
 *新增系统单位
 */

export const addOrg = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/orgs/addOrg", data);
};

/*
 *编辑系统单位
 */

export const editOrg = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/orgs/editOrg", data);
};

/*
 *编辑部门
 */

export const editDept = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/depts/editDept", data);
};
/*
 *删除单位
 */

export const delOrg = (id) => {
  return HttpService.del(HttpService.DOMAIN + "/gaea-system/orgs/delOrg/" + id);
};
/*
 *删除单位
 */

export const delDept = (id) => {
  return HttpService.del(HttpService.DOMAIN + "/gaea-system/depts/delDept/" + id);
};
/*
 *查找系统用户列表
 */

export const getUserList = (data) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-system/users/getUserList", data);
};

/*
新建用户
*/
export const addUser = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/users/addUser", data);
};

/*
编辑用户（包含角色）
*/
export const editUser = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/users/editUser", data);
};
/*
根据user_id查找系统用户
*/
export const loadUser = (id) => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-system/users/loadUser/" + id);
};
/*
删除
*/
export const delUser = (id) => {
  return HttpService.del(HttpService.DOMAIN + "/gaea-system/users/delUser/" + id);
};
/*
删除
*/
export const modifyUserPas = (data) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-system/users/modifyUserPas", data);
};
