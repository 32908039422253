import React, { useEffect, useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Image, Input, message } from "antd";
import { useHistory } from "react-router-dom";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import * as EBikeService from "../service/EBikeService";
import { setCookie } from "../service/HttpService";
import cookies from "react-cookies";
const Login = (props) => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [btn, setBtn] = useState({
    isLoading: false,
    name: "登录",
  });
  const handleLogin = () => {
    if (username.trim() == "") {
      message.error("用户名不能为空");
    } else if (password.trim() == "") {
      message.error("密码不能为空");
    } else {
      NProgress.start();
      setBtn({
        isLoading: true,
        name: "登录中",
      });
      EBikeService.login(username, password)
        .then((json) => {
          if (json.data.access_token) {
            setCookie(cookies, json.data);
            if (history.location.pathname === "/login" || history.location.pathname === "/") {
              history.push("/app");
            } else {
              history.push(history.location.pathname);
             
            }
          } else {
            message.error("用户名或密码错误");
            setBtn({
              isLoading: false,
              name: "登录",
            });
          }
        })
        .catch((err) => {
          setBtn({
            isLoading: false,
            name: "登录",
          });
          if (err.response) {
            if (err.response.status == 401 || err.response.status == 400) {
              message.error("用户名或密码错误");
            } else {
              console.error("登录请求发生异常:" + err);
              message.error("请求发生了异常,请联系管理员");
            }
          } else {
            console.error(err);
            message.error("网络连接失败");
          }
        });
      NProgress.done();
    }
  };

  return (
    <div className="login_container">
      <div className="login-form">
        <div className="login-title">闪换电后台管理系统</div>
        <div style={{ width: "408px", margin: "0 auto" }}>
          <p>用户名</p>
          <Input
            placeholder="请输入用户名"
            onChange={(e) => setUsername(e.target.value)}
            style={{ marginTop: "0.375rem" }}
            size="large"
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          />
          <p style={{ marginTop: "2rem" }}>密码</p>
          <Input.Password
            placeholder="请输入密码"
            onChange={(e) => setPassword(e.target.value)}
            onPressEnter={handleLogin}
            style={{ marginTop: "0.375rem", marginBottom: "2.1rem" }}
            size="large"
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          />
          <Button type="primary" onClick={handleLogin} loading={btn.isLoading} block={true} style={{ height: "56px" }}>
            {btn.name}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
