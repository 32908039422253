import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "antd/dist/antd.css";
import 'video-react/dist/video-react.css';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import MRoute from "./router/index";
import * as moment from "moment";
import momentConfig from "./config/momentConfig";
import { applyMiddleware, createStore } from "redux";
import appReducer from "./reducer/index.reducer";
import thunk from "redux-thunk";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";

// 设置moment js中文
moment.defineLocale("zh-cn", momentConfig);
const store = createStore(appReducer, applyMiddleware(thunk));
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <BrowserRouter>
        <MRoute />
      </BrowserRouter>
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);
