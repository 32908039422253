import React from "react";
import Loadable from "react-loadable";
import LoadingPage from "../components/LoadingPage";

/**
 * loadable封装
 */
export default (loader) => {
  return Loadable({
    loader,
    loading: (props) => {
      if (props.error) {
        return <div style={{ width: "100%", height: "100%" }}>加载错误。请刷新</div>;
      } else if (props.pastDelay) {
        return <LoadingPage />;
      } else {
        return null;
      }
    },
    delay: 300, // 0.3秒
  });
};
