import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse("abcdefghijklmnop"); 
//秘钥（后端提供）由于对称解密使用的算法是 AES-128-CBC算法，数据采用 PKCS#7 填充 ， 因此这里的 key 需要为16位！
const iv ="1234567890000000";
export const encryptByAES= (data)=> {
    var srcs = CryptoJS.enc.Utf8.parse(data);
    let cipherText = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return cipherText.toString();
  }
  export function decryptedByAES (data) {
    let decryptedData = CryptoJS.AES.decrypt(data, key, {
        iv: iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return decryptedData.toString(CryptoJS.enc.Utf8)
  
}
