import React from "react";
import {Spin} from "antd";

const LoadingPage = () => {

    return (
        <div style={{height: "100%", width: "100%"}} className="verticalCenterFlex">
            <div style={{textAlign: "center"}}>
                <Spin spinning={true} />
                <div>正在努力加载中....</div>
            </div>
        </div>
    )
}

export default LoadingPage;
