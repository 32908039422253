import { combineReducers } from "redux";
import packageData from "./sys_mgt_business_package.reducer";
import menuData from "./sys_mgt_menu.reducer";
/**
 * 系统配置Reducer
 */
const systemReducer = combineReducers({
  packageData, // 业务包数据
  menuData, // 菜单数据
});

export default systemReducer;
