import React from "react";
import Login from "../components/Login";
import cookies from "react-cookies";

const RouteGuard = (props) => {
  if (cookies.load("access_token") != null) {
    return props.children;
  } else {
    return <Login />;
  }
};

export default RouteGuard;
