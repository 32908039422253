import HttpConfig from "../config/HttpConfig";
import "whatwg-fetch";
import { message, Modal } from "antd";
import moment from "moment";
import cookies from "react-cookies";

// 域名配置
export const DOMAIN = HttpConfig.HTTP_APPLICATION_SERVER;

//websocket 的配置
export const SOCKETDOMAIN = HttpConfig.WEBSOCKET_SERVER;

// 是否刷新token
export var IS_REFRESH_TOKEN = true;

// 是否显示退出框
var IS_SHOW_LOGOUT_MODAL = true;

/**
 * post请求封装
 * @param url
 * @param data
 */
export const post = (url, data) => {
  var json = data || {};
  return request(url, {
    method: "POST",
    body: JSON.stringify(json),
  });
};

/**
 *  post表单数据
 * @param url url
 * @param data 表单数据
 */
export const postForm = (url, formData) => {
  return request(
    url,
    {
      method: "POST",
      body: formData,
    },
    true
  );
};

/**
 * delete
 * @param url
 * @param data
 */
export const del = (url, data) => {
  const json = data || {};
  return request(url, {
    method: "DELETE",
    body: JSON.stringify(json),
  });
};

/**
 * get 请求封装
 * @param url 请求路径
 */
export const get = (url) => {
  return request(url, {
    method: "GET",
  });
};
/**
 * get 请求封装
 * @param url 请求路径
 */
export const getNormal = (url, data) => {
  let dataStr = ""; //数据拼接字符串
  if (data) {
    Object.keys(data).forEach((key) => {
      dataStr += key + "=" + data[key] + "&";
    });
    if (dataStr !== "") {
      dataStr = dataStr.substr(0, dataStr.lastIndexOf("&"));
      url = url + "?" + dataStr;
    }
  }
  return request(url, {
    method: "GET",
  });
};

/**
 * put 请求封装--带参数
 * @param url 请求路径
 */
export const put_param = (url, data) => {
  const json = data || {};
  return request(url, {
    method: "PUT",
    body: JSON.stringify(json),
  });
};

/**
 * 封装fetch请求，支持异常捕获
 * @param url
 * @param options
 * @returns {Promise<Response>}
 */
const request = (url, options, isForm) => {
  let opt = options || {};
  let headers = {
    Authorization: "bearer " + getToken(),
    "content-type": "application/json",
  };
  if (isForm) {
    headers = {
      Authorization: "bearer " + getToken(),
    };
  }
  return fetch(url, {
    headers: headers,
    ...opt,
  })
    .then(checkStatus)
    .then(parseJSON)
    .then((json) => checkExpire(json, url, options, isForm))
    .then((data) => data);
};

const getToken = () => {
  return cookies.load("access_token", { path: "/", domain: HttpConfig.TOKEN_URL });
};

/**
 * 设置Cookie
 */
export const setCookie = (cookies, json) => {
  cookies.save("access_token", json.access_token, { path: "/", domain: HttpConfig.TOKEN_URL });
  cookies.save("token_type", json.token_type, { path: "/", domain: HttpConfig.TOKEN_URL });
  cookies.save("refresh_token", json.refresh_token, { path: "/", domain: HttpConfig.TOKEN_URL });
  cookies.save("expires_in", moment() + parseInt(json.expires_in) * 1000 - 10000, {
    path: "/",
    domain: HttpConfig.TOKEN_URL,
  });
  cookies.save("scope", json.scope, { path: "/", domain: HttpConfig.TOKEN_URL });
};

/**
 * 检查token是否过期
 * @param json
 */
const checkExpire = (json, url, options, isForm) => {
  if (json.code == 401001) {
    if (IS_REFRESH_TOKEN) {
      refreshTokenPreRequest();
    }
    IS_REFRESH_TOKEN = false;
    // 这个Promise函数很关键
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber(() => {
        resolve(request(url, options, isForm));
      });
    });
    return retryOriginalRequest;
  }
  return json;
};

/**
 * 刷新token
 */
function refreshTokenPreRequest() {
  const refreshToken = cookies.load("refresh_token");
  if (refreshToken) {
    let formData = new FormData();
    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", refreshToken);
    fetch(DOMAIN + "/gaea-auth/oauth/token", {
      method: "POST",
      body: formData,
      headers: { Authorization: HttpConfig.CLIENT_BASIC_AUTH },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((json) => {
        setCookie(cookies, json);
        onAccessTokenFetched();
        IS_REFRESH_TOKEN = true;
      })
      .catch((e) => {
        if (e.response.status == 401 || e.response.status == 400) {
          showLogOutModal();
        }
      });
  } else {
    showLogOutModal();
  }
}

/**
 * 检查响应状态
 */
const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401) {
    if (IS_SHOW_LOGOUT_MODAL) {
      IS_SHOW_LOGOUT_MODAL = false;
      showLogOutModal();
      return;
    }
  }
  const error = new Error(response.statusText);
  error.response = response;
  // throw error;
  message.error("服务器内部错误:" + response.status);
  return Promise.reject();
};

/**
 * 解析json
 */
function parseJSON(response) {
  return response.json();
}
/**
 * 请求回调订阅集合
 */
let subscribers = [];
function onAccessTokenFetched() {
  subscribers.forEach((callback) => {
    callback();
  });
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

/**
 * 显示登出模态框
 */
export const showLogOutModal = () => {
  Modal.confirm({
    title: "系统提示",
    cancelText: "取消",
    okText: "重新登录",
    content: "登录状态已过期，您可以继续留在该页面，或者重新登录",
    onOk: () => {
      IS_SHOW_LOGOUT_MODAL = true;
      cookies.remove("access_token", { path: "/", domain: HttpConfig.TOKEN_URL });
      cookies.remove("refresh_token", { path: "/", domain: HttpConfig.TOKEN_URL });
      cookies.remove("scope", { path: "/", domain: HttpConfig.TOKEN_URL });
      cookies.remove("expires_in", { path: "/", domain: HttpConfig.TOKEN_URL });
      cookies.remove("jti", { path: "/", domain: HttpConfig.TOKEN_URL });
      cookies.remove("token_type", { path: "/", domain: HttpConfig.TOKEN_URL });
      window.location = "/login";
    },
    onCancel: () => {
      IS_SHOW_LOGOUT_MODAL = true;
    },
  });
};

/**
 * post请求封装 不带Token
 * @param url
 * @param data
 */
export const postWithoutToken = (url, data) => {
  var json = data || {};
  return requestWithoutToken(url, {
    method: "POST",
    body: JSON.stringify(json),
  });
};

/**
 * 封装fetch请求，支持异常捕获 不带Token
 * @param url
 * @param options
 * @returns {Promise<Response>}
 */
const requestWithoutToken = (url, options) => {
  let opt = options || {};
  let headers = {
    "content-type": "application/json",
  };
  return fetch(url, {
    headers: headers,
    ...opt,
  })
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => data);
};
