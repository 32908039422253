import * as HttpService from "../../service/HttpService";
/* 查询合同列表 */
export async function queryContract(params) {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/contract/queryContract", params);
}
/* 
新增
*/
export async function insertContract(params) {
  return HttpService.post(HttpService.DOMAIN + "/gaea-crm/contract/insertContract", params);
}
/* 
编辑
*/
export async function updateContract(params) {
  return HttpService.put_param(HttpService.DOMAIN + "/gaea-crm/contract/updateContractById", params);
}

/* 
删除
*/
export async function removeContract(params) {
  return HttpService.del(HttpService.DOMAIN + "/gaea-crm/contract/removeContractById", params);
}

/* 
商机
*/
export async function getOpportuneLabel() {
  return HttpService.get(HttpService.DOMAIN + "/gaea-crm/crmOpportunity/getOpportuneLabel");
}
