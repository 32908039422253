import * as HttpService from "../../service/HttpService";

/**
 * 查询客户经理档案
 * @param params 参数列表
 */
export const getAccountManager = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-crm/accountManager/query",params);
};
/**
 * 获取所属区域树
 * @param params 参数列表
 */
export const getAdCodeTree = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/adCode/tree",params);
};
/**
 * 获取经理账号
 * @param params 参数列表
 */
export const getUserAccount = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-system/users/drop-down-box",params);
};
/*
 *新增客户经理
 */
export const addClientManager = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-crm/accountManager", params);
};
/*
 *修改客户经理
 */
export const updateClientManager = (params) => {
  return HttpService.put_param(HttpService.DOMAIN + "/gaea-crm/accountManager", params);
};
/*
 *删除客户经理
 */
export const deleteClientManager = (id) => {
  return HttpService.del(HttpService.DOMAIN + "/gaea-crm/accountManager/"+id);
};

























