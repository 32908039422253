import * as SystemService from "./systemService.js";
import * as ClientManagerArchiveMgtService from "./clientManagerArchiveMgtService.js";
import * as ClientMgtService from "./clientMgtService.js";
import * as customerService from "./customerService.js";
import * as visitService from "./visitService.js";
import * as contractMangementService from "./contractMangementService.js";
import * as OperationService from "./OperationService";
import * as OrderService from "./OrderService";
import * as dataViewService from "./dataViewService";
import * as DisasterPreventionService from "./disasterPreventionService";
import * as OrderChargeService from "./orderChargeService";
import * as SurveyWorkOrderService from "./surveyWorkOrderService.js";

/**
 *
 */
const RequestService = {
  SystemService, //系统参数
  ClientManagerArchiveMgtService, //客户经理档案管理
  ClientMgtService, //客户管理
  customerService, //大屏
  visitService, //走访管理
  contractMangementService,
  OperationService, //换电柜管理
  OrderService, //订单管理
  dataViewService, //数据概览
  DisasterPreventionService, //防灾应急
  OrderChargeService, //有序充电
  SurveyWorkOrderService, //勘察工单
};
export default RequestService;
