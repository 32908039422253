import * as HttpService from "../../service/HttpService";



/**
 * 有序充电电站列表
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12306
 */
export const queryOrderlyChargeStationList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/operOrderlyCharge/queryStationList", params);
};
/**
 * 执行有序充电
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12326
 */
export const executeOrderlyCharge = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/operOrderlyCharge/execute", params);
};
/**
 * 结束有序充电
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12334
 */
export const terminateOrderlyCharge = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/operOrderlyCharge/terminate", params);
};

/**
 * 发送验证码
 * http://yapi.ebike-charge.com:6060/project/437/interface/api/12310
 * @param {*} params 
 * @returns 
 */
export const sendSms = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/cfc/sendSms", params);
};




