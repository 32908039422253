// 开发环境代理配置
const PROXY_DEV = {
  HTTP_APPLICATION_SERVER: "http://192.168.1.119:7151", // 全局http请求应用路径
  HTTP_AUTHORIZATION_SERVER_URL: "http://192.168.1.218:7151/gaea-auth/oauth/token", // 全局http请求认证服务器路径
  CLIENT_BASIC_AUTH: "Basic d2ViLWNvbnNvbGU6ZG0uYXV0aCMyazIxLXdlYg==", // 客户端basic认证
  TOKEN_URL: "",
};

// 测试环境代理配置
const PROXY_TEST = {
  HTTP_APPLICATION_SERVER: "http://192.168.1.218:7151", // 全局http请求应用路径x
  HTTP_AUTHORIZATION_SERVER_URL: "http://192.168.1.218:7151/gaea-auth/oauth/token", // 全局http请求认证服务器路径
  CLIENT_BASIC_AUTH: "Basic d2ViLWNvbnNvbGU6ZG0uYXV0aCMyazIxLXdlYg==", // 客户端basic认证
  TOKEN_URL: "",
  WEBSOCKET_SERVER: "ws://192.168.1.218:7151",
};

// 生产环境代理配置
const PROXY_PRODUCT = {
  HTTP_APPLICATION_SERVER: "/api", // 全局http请求应用路径x
  CLIENT_BASIC_AUTH: "Basic d2ViLWNvbnNvbGU6ZG0uYXV0aCMyazIxLXdlYg==", // 客户端basic认证
  TOKEN_URL: "",
  WEBSOCKET_SERVER: "",
};

// 导出开发或生产环境
export default PROXY_PRODUCT;
